import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import M from 'materialize-css/dist/js/materialize.min.js'
import { MdDashboard } from 'react-icons/md'
import { FaLastfmSquare, FaMapMarkedAlt, FaTicketAlt } from 'react-icons/fa'
import { BsGraphUp } from 'react-icons/bs'
import { IoMdSettings } from 'react-icons/io'
import { FiLogIn, FiLogOut } from 'react-icons/fi'
import {
  MdLightMode,
  MdDarkMode,
  MdMiscellaneousServices,
  MdViewModule
} from 'react-icons/md'
import { toggleTheme } from '../../features/theme/themeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FaServer, FaSitemap } from 'react-icons/fa'
import { TbReportAnalytics } from 'react-icons/tb'
import { GrUserSettings } from 'react-icons/gr'
import { RiListSettingsLine } from 'react-icons/ri'
import { PiPlugChargingFill } from 'react-icons/pi'
import { GoProjectTemplate } from 'react-icons/go'
import { FaWpforms } from "react-icons/fa";
import { logoutUser } from '../../features/auth/authThunks'
import { resetCommentState } from '../../features/comment/commentSlice'
import { resetInverterState } from '../../features/inverter/inverterSlice'
import { getUserActivity } from '../../features/user/userThunks'
import { resetUserState } from '../../features/user/userSlice'
import { resetTenantState } from '../../features/tenant/tenantSlice'
import { resetSiteState } from '../../features/site/siteSlice'
import { resetAnalyticsState } from '../../features/analytics/analyticsSlice'
import {
  clearCurrentTicketExists,
  resetTicketState,
  setCurrentTicketExists
} from '../../features/ticket/ticketSlice'
import { resetTheme } from '../../features/theme/themeSlice'
import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai'
import roundLogo from '../../images/SolYieldLogoRound.png'
import { GrIntegration } from "react-icons/gr";
import { resetIntegrationState } from '../../features/integrations/integrationsSlice'
import { BsBoxes } from "react-icons/bs";

const Navbars = ({ extendNavbar }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const user = useSelector(state => state.auth.user)
  let tenant = localStorage.getItem('tenant')
  let current_ticket = localStorage.getItem('currentTicket')

  const [isExtended, setIsExtended] = useState(false)

  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    M.AutoInit()
  }, [])

  const [showAnalyticsSubOptions, setShowAnalyticsSubOptions] = useState(false)

  const [showSubOptions, setShowSubOptions] = useState(false)
  const subOptions = [
    { label: 'Account Settings', link: 'settings', icon: <GrUserSettings /> },
    {
      label: 'Site Settings',
      link: 'site-settings',
      icon: <RiListSettingsLine />
    },
    {
      label: 'Inverter Settings',
      link: 'inverter-settings',
      icon: <PiPlugChargingFill />
    },
    { label: 'Templates', link: 'templates', icon: <GoProjectTemplate /> },
    {
      label: 'Integrations',
      link: 'integrations',
      icon: <GrIntegration />
    },
    {
      label: 'Forms',
      link: 'forms',
      icon: <FaWpforms />
    }
  ]
  
  // if (tenant === 'Preview'){
  //   subOptions.push({
  //     label: 'Forms',
  //     link: 'forms',
  //     icon: <FaWpforms />
  //   })
  // }

  let analyticsSubOptions = []

  if (tenant === 'Preview') {
    analyticsSubOptions = [
      { label: 'Summary', link: 'analytics', icon: <TbReportAnalytics /> },
      { label: 'Sites', link: 'sites', icon: <FaSitemap /> },
      {
        label: 'Module Fault Detection',
        link: 'fault-detection',
        icon: <MdViewModule />
      }
      // { label: 'Analytics2', link: 'analytics2' }
    ]
  }else if(tenant === 'NRN') {
    analyticsSubOptions = [
      { label: 'Summary', link: 'analytics', icon: <TbReportAnalytics /> },
      { label: 'Sites', link: 'sites', icon: <FaSitemap /> },
      {
        label: 'Module Fault Detection',
        link: 'fault-detection',
        icon: <MdViewModule />
      },
      {
        label: 'New Sites',
        link: 'new-sites',
        icon: <BsBoxes />
      }
    ]
} else {
    analyticsSubOptions = [
      { label: 'Summary', link: 'analytics', icon: <TbReportAnalytics /> },
      { label: 'Sites', link: 'sites', icon: <FaSitemap /> },
      {
        label: 'Module Fault Detection',
        link: 'fault-detection',
        icon: <MdViewModule />
      }
    ]
  }

  const handleAnalyticsItemClick = () => {
    setShowAnalyticsSubOptions(!showAnalyticsSubOptions)
  }

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = (e) => {
    e.preventDefault()
    setIsHovered(true)
  }

  const handleMouseLeave = (e) => {
    e.preventDefault()
    setIsHovered(false)
  }

  const [isAnalyticsHovered, setIsAnalyticsHovered] = useState(false)

  const handleAnalyticsMouseEnter = (e) => {
    e.preventDefault()
    setIsAnalyticsHovered(true)
  }

  const handleAnalyticsMouseLeave = (e) => {
    e.preventDefault()
    setIsAnalyticsHovered(false)
  }

  const [darkMode, setDarkMode] = useState(false)

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
    // You can add additional logic here to toggle dark mode
    // For example, changing theme classes or saving user preference
  }

  const theme = useSelector(state => state.theme.theme)
  useEffect(() => {
    document.documentElement.classList.toggle('tw-dark', theme === 'dark')
  }, [theme])

  const handleSubOptionClick = subOption => {
    setIsHovered(false)
    setIsAnalyticsHovered(false)
    navigate(`/${subOption}`)
  }

  const { hostname, pathname } = window.location

  let logoUrl = 'https://solyieldclientfacing.s3.ap-south-1.amazonaws.com/SolYieldLogoRound.png-1704449604982'

  if(hostname === 'localhost' || hostname === 'solarbhaiops.in'){
    logoUrl = 'https://solyieldclientfacing.s3.amazonaws.com/solyieldLogo.jpg-1707744665701'
  }
  const activeItem = option => {
    if (option === pathname) {
      return true
    } else if (option === '/' && pathname.includes('/tickets/')) {
      return true
    } else if (
      option === '/analytics' &&
      (pathname === '/analytics' ||
        pathname === '/sites' ||
        pathname === '/fault-detection' ||
        pathname.includes('/view-site/'))
    ) {
      return true
    } else if (
      option === '/settings' &&
      (pathname === '/settings' ||
        pathname === '/inverter-settings' ||
        pathname === '/site-settings' ||
        pathname === '/templates')
    ) {
      return true
    } else {
      return false
    }
  }

  const setActive = () => {
    setCurrentPage(window.location.pathname)
    if (current_ticket) {
      localStorage.removeItem('currentTicket')
      dispatch(clearCurrentTicketExists())
    }
  }

  const onLogout = () => {
    dispatch(
      getUserActivity({
        inTime: Date.now(),
        exitTime: Date.now(),
        url: 'logout',
        logout: true
      })
    )
    dispatch(resetUserState())
    dispatch(resetTicketState())
    dispatch(resetCommentState())
    dispatch(resetAnalyticsState())
    dispatch(resetSiteState())
    dispatch(resetInverterState())
    dispatch(resetTenantState())
    dispatch(resetTheme())
    dispatch(logoutUser())
    dispatch(resetIntegrationState())
  }

  const toggleNavbar = () => {
    setIsExtended(!isExtended)
    extendNavbar()
  }


  useEffect(() => {
    const navbar = document.querySelector('.bottomBar');

    const handleMouseDown = (e) => e.preventDefault();
    const handleContextMenu = (e) => e.preventDefault();
    const handleSelectStart = (e) => {
        if (navbar.contains(e.target)) {
            e.preventDefault();
        }
    };

    navbar.addEventListener('mousedown', handleMouseDown);
    navbar.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('selectstart', handleSelectStart);

    return () => {
        navbar.removeEventListener('mousedown', handleMouseDown);
        navbar.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('selectstart', handleSelectStart);
    };
}, []);

  return (
    <div>
      {/* Sidebar for large screens */}
      <div
        className={`tw-hidden md:tw-flex ${
          isExtended ? 'tw-w-60' : 'tw-w-20'
        }  tw-h-full tw-fixed tw-inset-y-0 tw-left-0 tw-bg-transparent tw-transition-width tw-duration-300 tw-ease-in-out dark:tw-text-white tw-shadow-lg tw-z-50 tw-flex-col tw-justify-between tw-items-center  tw-border-solid tw-border-0 tw-border-r-2 tw-pt-2 tw-border-[#858589]`}
      >
        <div
          className={`tw-absolute  ${
            isExtended ? 'tw-left-60' : 'tw-left-20'
          } tw-top-2 tw-z-50 tw-bg-[#858589] tw-cursor-pointer tw-text-white tw-flex tw-justify-center tw-items-center tw-rounded-tr-full tw-rounded-br-full tw-p-1`}
          onClick={toggleNavbar}
        >
          {isExtended ? (
            <AiOutlineCaretLeft className='tw-text-2xl tw-mr-1' />
          ) : (
            <AiOutlineCaretRight className='tw-text-2xl ' />
          )}
        </div>
        {user && isAuthenticated ? (
          <div
            className={`tw-w-full tw-flex tw-flex-col tw-gap-1  tw-items-center`}
          >
            <Link to={'/'}>
            <img src={logoUrl} style={{ width: '60px', cursor: 'pointer' }} alt='logo' />
            </Link>
            
            <div>
              {isExtended ? (
                <p className='tw-font-bold tw-mb-0'>
                  Hi {`${user.firstName}`}
                </p>
              ) : (
                <p className='tw-font-bold tw-mb-0'>Hi </p>
              )}
            </div>
            <hr className='tw-w-full' />

            {user && user.userType !== 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-flex  ${
                  isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
                } tw-items-center tw-gap-2 dark:tw-text-white tw-text-black tw-w-full tw-h-10  ${
                  activeItem('/') && 'tw-bg-gray-300 dark:tw-bg-gray-700'
                } `}
                onClick={() => {
                  setActive()
                  navigate('/')
                }}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={`${isExtended ? '' : 'Dashboard'}`}
              >
                <MdDashboard className='tw-text-3xl' />
                {isExtended && (
                  <span className='tw-font-semibold'>Dashboard</span>
                )}
              </div>
            )}
            {user && user.userType === 'manager' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-gap-2 tw-flex ${
                  isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
                } tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/geo-spatial') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700'
                }`}
                onClick={() => {
                  setActive()
                  navigate('/geo-spatial')
                }}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={`${isExtended ? '' : 'Map'}`}
              >
                <FaMapMarkedAlt className=' tw-text-3xl' />
                {isExtended && <span className='tw-font-semibold'>Map</span>}
              </div>
            )}
            {/* {user && user.userType === 'manager' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 hover:tw-rounded-full tw-w-12 tw-h-10 tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/tickets') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700 tw-rounded-full'
                }`}
                onClick={() => {
                  setActive()
                  navigate('/tickets')}}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={'Tickets'}
              >
                <FaTicketAlt className=' tw-text-3xl' />
              </div>
            )} */}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-flex ${
                  isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
                } tw-gap-2 tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem(
                    user.sites.length === 1
                      ? `/view-site/${user.sites[0].value}`
                      : '/analytics'
                  ) && 'tw-bg-gray-300 dark:tw-bg-gray-700'
                }`}
                onClick={() => {
                  setActive()
                  navigate(
                    user.sites.length === 1
                      ? `/view-site/${user.sites[0].value}`
                      : '/analytics'
                  )
                }}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={`${
                  isExtended
                    ? ''
                    : user.sites.length === 1
                    ? `View site`
                    : 'Analytics'
                }`}
              >
                <BsGraphUp className=' tw-text-3xl' />
                {isExtended && (
                  <span className='tw-font-semibold'>
                    {user.sites.length === 1 ? `View site` : 'Analytics'}
                  </span>
                )}
              </div>
            )}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-flex ${
                  isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
                } tw-gap-2 tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/system-info') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700'
                }`}
                onClick={() => {
                  setActive()
                  navigate('/system-info')
                }}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={`${
                  isExtended ? '' : 'System Informations'
                }`}
              >
                <FaServer className=' tw-text-3xl' />
                {isExtended && (
                  <span className='tw-font-semibold'>System Informations</span>
                )}
              </div>
            )}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-flex ${
                  isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
                } tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/service-request') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700'
                }`}
                onClick={() => {
                  setActive()
                  navigate('/service-request')
                }}
                data-tooltip-id='app-tooltip'
                data-tooltip-content={`${isExtended ? '' : 'Service Request'}`}
              >
                <MdMiscellaneousServices className=' tw-text-3xl' />
                {isExtended && (
                  <span className='tw-font-semibold'>Service Request</span>
                )}
              </div>
            )}
            {user &&
              user.userType !== 'fieldEngineer' &&
              user.userType !== 'customer' && (
                <div
                  onMouseEnter={handleAnalyticsMouseEnter}
                  onMouseLeave={handleAnalyticsMouseLeave}
                  className='tw-w-full'
                >
                  <div
                    className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10  tw-flex ${
                      isExtended
                        ? 'tw-justify-start tw-pl-3'
                        : 'tw-justify-center'
                    } tw-items-center tw-gap-2 dark:tw-text-white tw-text-black ${
                      activeItem('/analytics') &&
                      'tw-bg-gray-300 dark:tw-bg-gray-700'
                    }`}
                    data-tooltip-id='app-tooltip'
                    data-tooltip-content={`${isExtended ? '' : 'Analytics'}`}
                  >
                    <BsGraphUp className=' tw-text-3xl' />
                    {isExtended && (
                      <span className='tw-font-semibold'>Analytics</span>
                    )}
                  </div>
                  {isAnalyticsHovered && (
                    <>
                      <div className={`tw-flex tw-flex-col tw-w-full `}>
                        {analyticsSubOptions.map((subOption, index) => (
                          <div
                            className={` hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-text-center tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black`}
                            data-tooltip-id='app-tooltip'
                            data-tooltip-content={`${
                              isExtended ? '' : subOption.label
                            }`}
                            key={index}
                          >
                            <span
                              className={` tw-p-2 tw-w-full tw-text-xl tw-flex ${
                                isExtended
                                  ? 'tw-justify-start tw-pl-3'
                                  : 'tw-justify-center'
                              } tw-gap-2 tw-items-center tw-gap-1   dark:tw-text-white  tw-text-black tw-opacity-85 tw-cursor-pointer`}
                              onClick={() =>
                                handleSubOptionClick(subOption.link)
                              }
                            >
                              {subOption.icon}
                              {isExtended && (
                                <span className='tw-font-semibold tw-text-sm'>
                                  {subOption.label}
                                </span>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}

            {user &&
              user.userType !== 'fieldEngineer' &&
              user.userType !== 'customer' &&
              user.userType !== 'technician' && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className='tw-w-full'
                >
                  <div
                    className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-flex ${
                      isExtended
                        ? 'tw-justify-start tw-pl-3'
                        : 'tw-justify-center'
                    } tw-items-center tw-gap-2 dark:tw-text-white tw-text-black ${
                      activeItem('/settings') &&
                      'tw-bg-gray-300 dark:tw-bg-gray-700'
                    }`}
                    data-tooltip-id='app-tooltip'
                    data-tooltip-content={`${isExtended ? '' : 'Settings'}`}
                  >
                    <IoMdSettings className=' tw-text-3xl' />
                    {isExtended && (
                      <span className='tw-font-semibold'>Settings</span>
                    )}
                  </div>
                  {isHovered && (
                    <>
                      <div className='tw-flex tw-flex-col tw-w-full '>
                        {subOptions.map((subOption, index) => (
                          <div
                            className=' hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-text-center tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black'
                            data-tooltip-id='app-tooltip'
                            data-tooltip-content={`${
                              isExtended ? '' : subOption.label
                            }`}
                            key={index}
                          >
                            <span
                              className={` tw-p-2 tw-w-full tw-text-xl tw-flex ${
                                isExtended
                                  ? 'tw-justify-start tw-pl-3'
                                  : 'tw-justify-center'
                              } tw-items-center tw-gap-2  tw-opacity-85 tw-cursor-pointer`}
                              onClick={() =>
                                handleSubOptionClick(subOption.link)
                              }
                            >
                              {subOption.icon}
                              {isExtended && (
                                <span className='tw-font-semibold tw-text-sm'>
                                  {subOption.label}
                                </span>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}

            {user &&
              (user.userType === 'fieldEngineer' ||
                user.userType === 'customer' ||
                user.userType === 'technician') && (
                <div className='tw-w-full'>
                  <div
                    className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-w-full tw-h-10 tw-flex ${
                      isExtended
                        ? 'tw-justify-start tw-pl-3'
                        : 'tw-justify-center'
                    } tw-items-center tw-gap-2 dark:tw-text-white tw-text-black ${
                      activeItem('/settings') &&
                      'tw-bg-gray-300 dark:tw-bg-gray-700'
                    }`}
                    onClick={() => {
                      setActive()
                      navigate('/settings')
                    }}
                    data-tooltip-id='app-tooltip'
                    data-tooltip-content={`${isExtended ? '' : 'Settings'}`}
                  >
                    <IoMdSettings className=' tw-text-3xl' />
                    {isExtended && (
                      <span className='tw-font-semibold'>Settings</span>
                    )}
                  </div>
                </div>
              )}
          </div>
        ) : (
          <div>
            <Link to={'/'}>
            <img src={logoUrl} style={{ width: '60px' }} alt='logo' />
            </Link>
          <Link
            to={'/login'}
            className={`tooltipped tw-mt-5 tw-w-full tw-text-center dark:tw-text-white  tw-text-black tw-flex ${
              isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
            } tw-items-center tw-gap-2`}
            data-position='right'
            data-tooltip={'Login'}
          >
            <FiLogIn className=' tw-text-3xl' />
            {isExtended && <span className='tw-font-semibold'>Login</span>}
          </Link>
          </div>
        )}

        <div className='tw-mb-5 tw-w-full'>
          {/* <button
            onClick={toggleDarkMode}
            className='tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full tw-border tw-border-gray-200 dark:tw-border-gray-700 tw-bg-gray-200 dark:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-blue-300 dark:focus:tw-ring-gray-600 tw-relative tw-overflow-hidden tw-transition-all tw-duration-300'
          >
            <div
              className={`tw-absolute tw-transition-transform tw-duration-300 ${
                darkMode ? 'tw--translate-x-full' : 'tw-translate-x-0'
              }`}
            >
              <MdLightMode className='tw-w-6 tw-h-6 tw-text-yellow-500 dark:tw-text-yellow-300' />
            </div>
            <div
              className={`tw-absolute tw-transition-transform tw-duration-300 ${
                darkMode ? 'tw-translate-x-0' : 'tw-translate-x-full'
              }`}
            >
              <MdDarkMode className='tw-w-6 tw-h-6 tw-text-gray-500 dark:tw-text-gray-300' />
            </div>
          </button> */}
          <div
            onClick={() => dispatch(toggleTheme())}
            className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-mb-2 tw-h-10 tw-flex tw-items-center tw-gap-2 ${
              isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
            }`}
          >
            {theme === 'light' ? (
              <MdDarkMode className='tw-text-3xl' />
            ) : (
              <MdLightMode className='tw-text-3xl' />
            )}
            {isExtended && <span className='tw-font-semibold'>Theme</span>}
          </div>
          {user && isAuthenticated && (
            <div
              className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-h-10 tw-flex tw-items-center tw-gap-2 ${
                isExtended ? 'tw-justify-start tw-pl-3' : 'tw-justify-center'
              }`}
              onClick={onLogout}
            >
              <FiLogOut className='tw-text-3xl' />
              {isExtended && <span className='tw-font-semibold'>Logout</span>}
            </div>
          )}
        </div>
      </div>

      {/* Bottom bar for small screens */}
      <div className='md:tw-hidden tw-fixed tw-top-0 tw-z-50 tw-h-12 tw-w-screen tw-bg-[#eee] dark:tw-bg-blue-gradient dark:tw-text-white tw-border-solid tw-border-0 tw-border-b-2 tw-border-[#858589] tw-flex tw-justify-between'>
        <div className='tw-flex tw-items-center tw-gap-2'>
        <Link to={'/'}>
          <img src={logoUrl} style={{ width: '35px' }} alt='logo' />
          </Link>
          {user && 
          <div style={{userSelect:'none'}}>
            <p className='tw-font-bold tw-m-0'>
              Hi {`${user?.firstName}`},
            </p>
          </div>
          }
        </div>
        <div className='tw-flex tw-gap-5 tw-items-center'>
          <div onClick={() => dispatch(toggleTheme())}>
            {theme === 'light' ? (
              <MdDarkMode className='tw-text-3xl' />
            ) : (
              <MdLightMode className='tw-text-3xl' />
            )}
          </div>
          <div>
            {user && isAuthenticated && (
              <FiLogOut className='tw-text-3xl' onClick={onLogout} />
            )}
          </div>
        </div>
      </div>
      <div className='bottomBar md:tw-hidden tw-fixed tw-inset-x-0 tw-bottom-0 tw-bg-[#eee] dark:tw-bg-blue-gradient dark:tw-text-white tw-shadow-lg tw-flex tw-items-center tw-whitespace-nowrap tw-h-16 tw-z-[1001] tw-border-solid tw-border-0 tw-border-t-2 tw-border-[#858589] ' style={{justifyContent:'safe center', overflowX: user && user.userType === 'customer' && 'auto'}}>
        {user && isAuthenticated ? (
          <>
            {user && user.userType !== 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  dark:tw-text-white tw-text-black  tw-p-3 ${
                  activeItem('/') && 'tw-bg-gray-300 dark:tw-bg-gray-700 '
                } `}
                onClick={() => {
                  setActive()
                  navigate('/')
                }}
                style={{userSelect:'none'}}
              >
                Dashboard
              </div>
            )}
            {user && user.userType === 'manager' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/geo-spatial') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700 '
                }`}
                onClick={() => {
                  setActive()
                  navigate('/geo-spatial')
                }}
                style={{userSelect:'none'}}
              >
                Map
              </div>
            )}
            {/* {user && user.userType === 'manager' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  tw-h-10 tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/tickets') && 'tw-bg-gray-300 dark:tw-bg-gray-700'
                }`}
                onClick={() => {
                  setActive()
                  navigate('/tickets')}}
              >
                Tickets
              </div>
            )} */}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem(
                    user.sites.length === 1
                      ? `/view-site/${user.sites[0].value}`
                      : '/analytics'
                  ) && 'tw-bg-gray-300 dark:tw-bg-gray-700 '
                }`}
                onClick={() => {
                  setActive()
                  navigate(
                    user.sites.length === 1
                      ? `/view-site/${user.sites[0].value}`
                      : '/analytics'
                  )
                }}
                style={{userSelect:'none'}}
              >
                {user.sites.length === 1 ? `View site` : 'Analytics'}
              </div>
            )}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/system-info') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700 '
                }`}
                onClick={() => {
                  setActive()
                  navigate('/system-info')
                }}
                style={{userSelect:'none'}}
              >
                System Informations
              </div>
            )}
            {user && user.userType === 'customer' && (
              <div
                className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                  activeItem('/service-request') &&
                  'tw-bg-gray-300 dark:tw-bg-gray-700 '
                }`}
                onClick={() => {
                  setActive()
                  navigate('/service-request')
                }}
                style={{userSelect:'none'}}
              >
                Service Request
              </div>
            )}
            {user &&
              user.userType !== 'fieldEngineer' &&
              user.userType !== 'customer' && (
                <div
                  onMouseEnter={handleAnalyticsMouseEnter}
                  onMouseLeave={handleAnalyticsMouseLeave}
                  style={{userSelect:'none'}}
                >
                  <div
                    className={`tw-relative hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700   tw-p-3  dark:tw-text-white tw-text-black ${
                      activeItem('/analytics') &&
                      'tw-bg-gray-300 dark:tw-bg-gray-700 tw-cursor-pointer'
                    } tw-z-50`}
                  >
                    Analytics
                  </div>
                  {isAnalyticsHovered && (
                    <>
                      <div className='tw-absolute tw-bottom-full tw-w-full tw-bg-gray-300  dark:tw-bg-gray-700 tw-mt-2 '>
                        {analyticsSubOptions.map((subOption, index) => (
                          <div key={index} className='tw-p-1 tw-text-white hover:tw-bg-gray-600 tw-flex tw-justify-center tw-items-center tw-cursor-pointer'>
                            <span
                              className=' tw-p-2 tw-w-full tw-text-sm   dark:tw-text-white  tw-text-black tw-opacity-85 '
                              onClick={() =>
                                handleSubOptionClick(subOption.link)
                              }
                            >
                              {subOption.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}

            {user &&
              user.userType !== 'fieldEngineer' &&
              user.userType !== 'customer' &&
              user.userType !== 'technician' && (
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={`tw-relative hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700  tw-h-10 tw-p-3  dark:tw-text-white tw-text-black ${
                      activeItem('/settings') &&
                      'tw-bg-gray-300 dark:tw-bg-gray-700 tw-cursor-pointer'
                    }`}
                    style={{userSelect:'none'}}
                  >
                    {/* <span
                      className='tooltipped tw-mt-5 tw-w-full tw-text-center dark:tw-text-white  tw-text-black'
                      data-position='right'
                      data-tooltip={'Settings'}
                    > */}
                    {/* <IoMdSettings className=' tw-text-3xl' /> */}
                    Settings
                    {/* </span> */}
                  </div>
                  {isHovered && (
                    <>
                      <div className='tw-absolute tw-bottom-full tw-right-4 dark:tw-bg-gray-700 tw-bg-gray-300  tw-mt-2  '>
                        {subOptions.map((subOption, index) => (
                          <div key={index} className=' hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700 tw-text-right tw-flex tw-justify-center tw-items-center tw-cursor-pointer dark:tw-text-white tw-text-black'>
                            <span
                              className=' tw-p-2 tw-w-full tw-text-sm   tw-opacity-85 '
                              onClick={() =>
                                handleSubOptionClick(subOption.link)
                              }
                            >
                              {subOption.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}

            {user &&
              (user.userType === 'fieldEngineer' ||
                user.userType === 'customer' ||
                user.userType === 'technician') && (
                <div
                  className={`hover:tw-bg-gray-300 dark:hover:tw-bg-gray-700   tw-h-10 tw-p-3 tw-flex tw-justify-center tw-items-center dark:tw-text-white tw-text-black ${
                    activeItem('/settings') &&
                    'tw-bg-gray-300 dark:tw-bg-gray-700 '
                  }`}
                  onClick={() => {
                    setActive()
                    navigate('/settings')
                  }}
                  style={{userSelect:'none'}}
                >
                  {/* <Link
                    to={'/settings'}
                    className='tooltipped tw-mt-5 tw-w-full tw-text-center dark:tw-text-white  tw-text-black'
                    data-position='right'
                    data-tooltip={'Settings'}
                  > */}
                  {/* <IoMdSettings className=' tw-text-3xl' /> */}
                  Settings
                  {/* </Link> */}
                </div>
              )}
          </>
        ) : (
          <Link
            to={'/login'}
            className=' tw-mt-5 tw-w-full tw-text-center dark:tw-text-white  tw-text-black'
          >
            <FiLogIn className=' tw-text-3xl' />
          </Link>
        )}
      </div>
      {/* <Tooltip id='app-tooltip' style={{ zIndex: 1000 }} /> */}
    </div>
  )
}

export default Navbars
